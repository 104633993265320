<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 运费模版 </template>
      <template #input>
        <a-button class="all_boder_btn btn" @click="toDetail()">新建模版</a-button>
      </template>
    </HeaderBox>

    <!-- 表格 -->
    <div class="tables">
      <div class="item" v-for="(item, index) in tableData" :key="index">
        <div class="titleH4">
          <div class="title">{{ item.name }}</div>
          <div class="flex">
            <div>最后编辑时间：{{item.modifyTime}}</div>
            <div>
              <a @click="toDetail(item.freightId)">修改</a>
              <span>|</span>
              <a @click="deleteData(item.freightId)">删除</a>
            </div>
          </div>
        </div>
        <table class="table">
          <!-- 全国包邮表头  -->
          <tr>
            <th>运送到</th>
            <th>{{item.priceManner == 1 ? '首件（个）' : '首重（kg）'}}</th>
            <th>运费（元）</th>
            <th>{{item.priceManner == 1 ? '续件（个）' : '续重（kg）'}}</th>
            <th>运费（元）</th>
          </tr>
          <tbody>
            <tr>
              <!-- item.noFreight == 2  为全国包邮，所有运费为0 -->
              <td style="min-width:100px">全国</td>
              <td>{{item.noFreight == 2 ? 1 : item.defaultCount}}</td>
              <td>{{item.noFreight == 2 ? 0.0 : item.defaultPrice}}</td>
              <td>{{item.noFreight == 2 ? 1 : item.addCount}}</td>
              <td>{{item.noFreight == 2 ? 0.0 : item.addPrice}}</td>
            </tr>
            <tr v-for="(ioom, indexs) in item.freightShipList" :key="indexs">
              <td><a @click="onOpenView(ioom.provice)">查看指定地区</a></td>
              <td>{{ ioom.firstCount }}</td>
              <td>{{ ioom.firstPrice }}</td>
              <td>{{ ioom.addCount }}</td>
              <td>{{ ioom.addPrice }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- 指定地区弹窗 -->
    <a-modal v-model="modalViewShow" @ok="modalViewShow = false" title="指定地区">
      <div class="adress_list">
        <div class="adress_item" v-for="item in selectAearArry" :key="item.code">
          <p>{{item.name}}：</p>
          <span v-for="subItem in item.children" :key="subItem.code">{{subItem.name}}</span>
        </div>
      </div>
    </a-modal>
    <a-empty v-show="total == 0" style="padding:50px 0" />
    <div class="bottom_box" v-show="total !=0 ">
      <a-pagination
        v-model="current"
        @change="selPagenumber"
        :total="total"
        show-less-items
      />
    </div>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return { 
      modalViewShow:false,
      selectAearArry:[],
      pageNum: 1,
      current: 2, 
      tableData: null, 
      total: 0
    };
  },
  // 事件处理器
  methods: {
    // 分页回调
    selPagenumber(e) {
      this.pageNum = e;
      this.getdataList();
    },
    // 跳详情页
    toDetail(id) {
      this.$router.push({ path: "/admin/BasicConfig/FreightTemplateDetails", query: { freightId: id } })
    },
    // 删除标签
    deleteData(id) {
      let _this = this
      this.$confirm({
        title: '确定删除该数据吗?',
        okText: '确认',
        onOk() {
          _this.$ajax({
            url: '/hxclass-management/product-freight/manage/delete?freightId=' + id,
            method: "DELETE",
          }).then((res) => {
            if (res.code == 200 && res.success) {
              _this.$message.success('删除成功');
              _this.getdataList()
            }
          })
        },
        onCancel() {},
      });
    },
    // 查看地区时，做省市递归处理
    onOpenView(e){
      let proviceArry = []
      let treeData = []
      this.selectAearArry = JSON.parse(e)
      this.selectAearArry.forEach(element => {
        if((element.code).slice(2,6) == '0000'){
          proviceArry.push({
            name: element.name,
            code: element.code,
            children: [],
          })
        }
      });
      proviceArry.forEach((element,index) => {
        this.selectAearArry.forEach(subItem => {
          if(((element.code).slice(0,2) == (subItem.code).slice(0,2)) && (element.code != subItem.code)){
            element.children.push({
              name: subItem.name,
              code: subItem.code,
              children: [],
            })
          }
        });
      });
      this.selectAearArry = proviceArry
      this.modalViewShow = true
    },
    // 获取数据
    getdataList() {
      this.$ajax({
        url: "/hxclass-management/product-freight/manage/list",
        method: "get",
        params: {
          page: this.pageNum,
          size: 10,
        },
      }).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {
    this.getdataList();
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.tables {
  .item {
    margin-top: 32px;
    .titleH4 {
      height: 44px;
      background-color: #f6f6fc;
      border: 1px solid #eee;
      border-bottom: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      .title {
        padding: 15px;
        font-weight: 500;
        font-size: 14px;
        color: #333;
        flex: 1;
      }
      .flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        > div {
          font-size: 12px;
          font-weight: 400;
          margin-right: 15px;
          margin-left: 50px;
          span {
            margin: 0 5px;
          }
        }
      }
    }
    .table {
      width: 100%;
      border-collapse: collapse;
      th,
      td {
        height: 44px;
        border: 1px solid #eee;
        padding: 5px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
      }
      th {
        font-weight: 500;
      }
    }
  }
}

.adress_list{
  .adress_item{
    margin-bottom: 15px;
    >p{
      color: #333;
      font-size: 16px;
      // margin-bottom: 5px;
    }
    >span{
      display: inline-block;
      font-size: 14px;
      min-width: 100px;
    }
  }
}

.bottom_box {
  text-align: center;
  margin-top: 30px;
}
</style>
